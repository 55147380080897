// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  userProfile: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DROPS_DATA':
      // console.log('GET_ALL_DATA from dropcards')
      return { ...state, allData: action.data, data: action.data }
    case 'GET_DROPS_DATA':
      return {
        ...state,
        data: action.data,
        allData: action.data
      }
    // case 'ADD_DATA':
    //   return {
    //     ...state,
    //     allData: state.push(action.data)
    //   }
    // case 'UPDATE_DATA':
    //   return {
    //     ...state,
    //     userProfile: action.data
    //   }
    // case 'DELETE_DATA':
    //   return { 
    //     ...state,
    //     allData: state.map(allData => {
    //       if (allData.id === action.payload) {
    //         return { ...allData, complete: false };
    //       } 
    //     })
    //  }
    // case 'GET_USER':
    //   return { ...state, 
    //     selectedUser: action.selectedUser
    //    }
    //    case 'CURRENT_USER':
    //   return { ...state, 
    //     userProfile: action.data
    //    }
    default:
      return { ...state }
  }
}
export default users
