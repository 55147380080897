const initialState = {
  chats: [],
  contacts: [],
  userProfile: {},
  selectedUser: {},
  selectedUserChannel: null,
  selectedUserChats: [],
  selectedUserNewChats: []
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'SET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chats: action.data.chatsContacts, contacts: action.data }
      // return { ...state, chats: action.data.chatsContacts, contacts: action.data.contacts }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'SELECT_CHANNEL':
      return { ...state, selectedUserChannel: action.data }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data.response.chat
      return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    case 'SAVE_MSG':
      return { ...state, selectedUserChats: action.data ? action.data : [] }
    case 'ADD_NEW_MSG':
      const ADD = [...state.selectedUserNewChats, action.data]
      return { 
        ...state, 
        selectedUserNewChats: ADD
        // selectedUserNewChats: [...state.selectedUserNewChats, action.data] 
      }
    case 'CLEAR_MSG':
      return { ...state, selectedUserChats: []}
    case 'CLEAR_NEW_MSG':
      return { ...state, selectedUserNewChats: [] }
    default:
      return state
  }
}

export default chatReducer
