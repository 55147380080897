// ** Initial State
const initialState = {
  dropcardList: []
}
  
  const dropcards = (state = initialState, action) => {
    switch (action.type) {
      case 'BOOTH_DROPCARDS':
        return { ...state, dropcardList: action.data }
      default:
        return { ...state }
    }
  }
  export default dropcards
  